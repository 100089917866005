import React from 'react';

import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, Fa } from "mdbreact";
import './header-component.css';
import SkiddooLogo from '../images/skiddoo.png';
class HeaderComponent extends React.Component {

  render() {

    return (
      <div className="header-component">

        <Navbar className="header-nav" dark expand="md" fixed="top" scrolling>

          <NavbarBrand href="/">
            <img
              src={SkiddooLogo}
              alt=""
              height="75"
            />
          </NavbarBrand>

        </Navbar>

      </div>

    );
  }
}

HeaderComponent.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default HeaderComponent;
