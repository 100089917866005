import React from 'react';

import { Container } from "mdbreact";
import './terms-cond-component.css';
import HeaderContainer from '../header/header-container';

class TermsConditionsComponent extends React.Component {

  render() {
    return (
      <div className="home-component">
        <HeaderContainer {...this.props} />
        <div>
          <div>
            <Container  className="mt-4 search-filter">

            <div className="search-label">
               <p><font size="6"><b>Terms of use</b></font></p>
               <font size="3">
               <p><b>PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY BEFORE USING THIS SITE.</b><br/>
               Your use of this site is expressly conditioned on your acceptance of the Terms and Conditions. By using this site, you signify that you unconditionally agree and accept to be legally bound by the Terms and Conditions. If you do not agree with any part of the Terms and Conditions, you must not use this site. The Terms and Conditions apply to the use of the site. Certain products and services have their own specific governing terms and conditions, which shall apply in addition to the Terms and Conditions. These terms and conditions for a specific product or service shall include any terms and conditions found on your screens and when using any part of this site. In the event of any inconsistency between the terms and conditions for a specific product or service and the Terms and Conditions, the terms and conditions for the specific product or service shall prevail in relation to that specific product or service. </p>
               <p><b>1. Ownership</b><br/>
               Skiddoo Pty Ltd ("Skiddoo") is the operator of this site (the "Operator").This site, and each of its modules, together with the arrangement and compilation of the content found on this site, is the copyrighted property of Skiddoo and/or its various third party providers and distributors and which is licensed to the Operator. Much of the content found on this site is owned by third party providers and distributors though certain data is the copyrighted work of Skiddoo and other information providers. In addition, the trademarks, logos and service marks displayed on this site (collectively, the "Trademarks") are registered and common law Trademarks of Skiddoo , its affiliates, and various third parties. Nothing contained on this site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the content on the site or Trademarks without the prior written permission of Skiddoo , or such other party that may own the content on the site or Trademarks.</p>
               <p><b>2. Use Of Site</b><br/>
               The Operator (as the case may be) grant you a limited, non-exclusive, non-transferable right to use this site in accordance with the Terms and Conditions. You agree that you:<br/>
               <b>a.</b> shall only use this site to make legitimate reservations or purchases and shall not use this site for any other purposes, including without limitation, to make any speculative, false or fraudulent reservation or any reservation in anticipation of demand.<br/>
               <b>b.</b> shall not employ misleading email addresses or falsify information in the header, footer, return path, or any part of any communication, including emails, transmitted through this site.<br/>
               <b>c.</b> shall not, except as permitted under applicable law, copy, reproduce, republish, upload, post, transmit distribute or otherwise communicate to the public any o the content provided in this site, including the text, graphics, button icons, audio and video clips, digital downloads, data compilations and software, without the prior written permission of the Operator, Skiddoo and/or its third party providers and distributors, except that you may download, display and print the materials presented on this site for your personal, non-commercial use only.<br/>
               <b>d.</b> shall not use any "robot," "spider", "crawler" or other automated device, or a program, algorithm or methodology having similar processes or functionality, or any manual process, to monitor or copy any of the web pages, data or content found on this site, in any case without the prior written permission of the Operator.<br/>
               <b>e.</b> shall not, except as permitted under applicable law, alter, modify, translate, decompile, adapt, disassemble, reverse engineer or create derivative works from any content or component from this site.<br/>
               <b>f.</b> shall not transmit or otherwise transfer any web pages, data or content found on this site to any other computer, server, web site, or other medium for mass distribution or for use in any commercial enterprise. The use of such materials on any other web site or in any environment of networked computers is prohibited.<br/>
               <b>g.</b> shall not use any device, software or routine to interfere or attempt to interfere with the proper working of this site.<br/>
               <b>h.</b> shall not take any action that imposes a burden or load on our infrastructure that the Operator deems in their sole discretion to be unreasonable or disproportionate to the benefits the Operator obtains from your use of the site.<br/>
               <b>i.</b> shall not make any unauthorized use of this site and/or the materials contained on this site to violate applicable copyright, trademark or other intellectual property laws or other laws. You must retain all copyright and trademark notices, including any other proprietary notices, contained in the materials, and you must not alter, obscure or obliterate any of such notices.<br/>
               <b>j.</b> shall not post or transmit any unlawful, threatening, libelous, defamatory, obscene, indecent, inflammatory, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law or applicable regulation or code of conduct.<br/>
               <b>k.</b> shall not post or transmit any information which (i) infringes the rights of others or violates their privacy or publicity rights, (ii) is protected by copyright, trademark or other proprietary right, unless with the express written permission of the owner of such right, (iii) contains a virus, bug, worm , time bomb, trojan horse, trap door or other harmful item, (iv) is used to unlawfully collude against another person in restraint of trade or competition, or (v) creates any liability on the Operator or causes the Operator to lose (in whole or in part) the services of our internet service providers or other suppliers. You agree that you shall be solely liable for any damages resulting from any infringement of copyright, trademark, or other proprietary right, or any other loss or harm resulting from your use of this site.
               </p>
               <p><b>3. Registration</b><br/>
               Use of this site may require you to register and provide certain data. In consideration of the Operator permitting you to use the site, in registering and providing such data, you represent and warrant that: (a) the information about yourself is true, accurate, current, and complete (apart from optional items) as required by various registration forms on the site (the "Registration Data") and (b) you will maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or the Operator has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, the Operator has the right to suspend or terminate your account and refuse any and all current or future access to and use of this site.<br/>Upon registering, you will receive a password and user ID. You are responsible for maintaining the confidentiality of the password and user ID, and are fully responsible for all activities that occur under your password or user ID. You may only maintain one user ID and password at any one time and may not use multiple user IDs or passwords. You are required to use your password for your own use only. You may not permit others to use your passwords. You agree to (a) immediately notify the Operator of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. The Operator cannot and will not be liable for any loss or damage arising from your failure to comply with this requirement.
               </p>
               <p><b>4. Privacy</b><br/>
               You have read the Operator's Privacy Policy, the terms of which are incorporated herein, and agree that the terms of such policy are reasonable. You consent to the use and processing of your Registration Data and other personally identifiable user information by the Operator and/or its third party providers and distributors in accordance with the terms of and for the purposes set forth in the Operator's Privacy Policy. You acknowledge and agree that the technical processing of this site and the transmission of transaction messages relating to the products and services on this site may involve (a) transmissions of your Registration Data and other data collected and maintained by the Operator with regard to you, over various networks and to various countries; and (b) changes to conform and adapt your Registration Data and other data collected and maintained by the Operator with regard to you to technical requirements of connecting networks or devices. Neither the Operator or Skiddoo will be held responsible for events arising from third parties gaining unauthorised access to such data.</p>
               <p><b>5. Review Of Transmissions</b><br/>
               The Operator is not obliged to but may, from time to time monitor and review any information transmitted or received through this site and reserves the right to sensor, edit, remove or prohibit the transmission or receipt of any information that the Operator deems inappropriate or in violation of the Terms and Conditions. During monitoring, the information may be examined, recorded or copied, and your use of this site constitutes your consent to such monitoring, copying and review. You agree that if you submit suggestions, ideas, comments or questions or post any other information on this site, you grant the Operator and its affiliates a worldwide, non-exclusive, royalty-free, perpetual and irrevocable right (including the right to sub-license) to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, display and otherwise communicate to the public such content in any form, media or technology. You also waive all moral rights in relation to any such use of this content whether or not that use is presently contemplated. The Operator takes no responsibility and assumes no liability for any content posted or submitted by you.</p>
               <p><b>6. Age & Responsibility</b><br/>
               If you use this site, you are responsible for maintaining the confidentiality of your account information and your password. You agree to accept responsibility for all activities that occur under your account or password. You represent that you are of sufficient legal age to use this site and to create binding legal obligations for any liability you may incur as a result of the use of this site. You understand that you are financially responsible for all uses of this site by you and those using your login information.</p>
               <p><b>7. Online Booking & Purchases</b><br/>
               Contracts for products and services<br/>
               Unless otherwise expressly stated on the site, the Operator acts as agent for its third party providers and distributors for all products and services purchased by you on this site. You may place bookings for or may make purchases of products and services online at this site. A contract for the sale and purchase of products and services may be made online at this site by your acceptance of the products and services offered on the site (the "Contract") by third party providers and/or distributors and/or where stated, the Operator, and you hereby agree to waive any rights to challenge the validity or enforceability of Contracts entered into on this site on the grounds that it was made in electronic form instead of by paper and/or signed or sealed. Please note that by indicating your acceptance to purchase any product or service offered on the site, you are obligated to complete such transactions. You are prohibited from indicating your acceptance to purchase products and services where you do not intend to complete such transactions. You may not purchase goods or services that you are prohibited from purchasing or possessing by any applicable local laws. The responsibility for ensuring compliance with all applicable laws shall be yours alone. By submitting an order to purchase goods or services, you represent and warrant that you have the legal right to purchase, utilise and/or possess such goods or services.<br/>
               You are solely responsible for checking that the booking and/or purchase made contains the correct information (including dates, names and timings) before proceeding to payment. The Operator and the third party providers and distributors shall be entitled to rely on the information contained in your booking and shall not be responsible for any error(s) made in your booking and/or purchase.</p>
               <p>Third party travel service providers<br/>
               You may be bound by terms and conditions imposed by the travel service providers for whom the Operator acts as an agent including conditions of carriage, refund and cancellation policies of airlines, cruise lines, car hire operators, hotels, etc.</p>
               <p>The Operator is not liable for any claims against non-fulfillment or unsatisfactory fulfillment of products and services purchased on your behalf by the Operator from these third party providers and distributors, such as, but not limited to, airlines, hotels, tour operators, car hire companies, insurance companies, and other entities. At times, airlines and other travel services providers may overbook passengers on their flights or re-schedule flight times-the Operator is not responsible for any such incidents.</p>
               <p>Fufilment<br/>
               You understand that all products and services purchased require fulfillment and documentation, such as issuance of air tickets and travel vouchers. Fulfillment is performed during normal office hours. As such, any travel requests and bookings made require a minimum processing time of varying periods, subject to the specific terms and conditions applicable to the type of product or service purchased.</p>
               <p>The Operator will not be held liable for any claims for loss, damages or compensation against non-fulfillment should you purchase a product or service that cannot be fulfilled between the time of booking and the time of departure. In such an event, the Operator shall refund to you all unutilised monies collected from you for that purchase.</p>
               <p>Passport, visa and health information<br/>
               Many countries require that foreign nationals entering hold a passport with at least six (6) months validity remaining. This information as well as other related information supplied by the Operator or any of its affiliates and any links within the site are supplied in good faith; and should be treated as a guideline only. Passport, Visa (including any and all transit visas) and Health requirements are the responsibility of all individual travellers. The sole responsibility for ensuring travel documentation is correct is that of the individual traveller. The Operator reserves the right to decline any booking or purchase for any reason and shall not be held liable for any resulting claims for loss, damages or compensation. In such an event, the Operator shall refund to you all unutilized monies collected from you for that purchase.</p>
               <p>Over-lapping bookings<br/>
               You agree that multiple over-lapping bookings for the same traveler/s are not allowed and may result in damage and loss to the Operator, which you will bear in full.</p>
               <p>Specific terms and conditions for products and services<br/>
               Certain products and services have their own specific governing terms and conditions which shall apply in addition to the Terms and Conditions. It is important for you to obtain and read the terms and conditions applicable to these certain products and services as they may contain exclusions of limitations of liability and other terms and conditions, including restrictions on amendment or cancellation. Some of these terms and conditions for specific products and services shall include terms and conditions found on your screens and when using any part of this site. In the event of any inconsistency between the terms and conditions for the specific product or service and these Terms and Conditions, the terms and conditions for the specific product or service shall prevail in relation to that specific product or service.</p>
               <p>Availability of Products<br/>
               Information on the site may include references or cross references to products and/or services which may not be available for your country. Such references do not imply that the Operator intends to make available such products and/or services in your country. If in doubt, please contact our Sales and Customer Care Team for more information.</p>
               <p><b>8.</b> Unless otherwise expressly stated, all prices on this site shall be exclusive of all taxes (including goods and services tax (GST) levies, fees, airport pick up charges and all other charges together with any fine, penalty or interest payable because of your default. You must pay any amount you are required to pay under this clause 7 in full on demand.<br/>
               All prices displayed for the product and service are subject to change and availability by their respective providers, and such prices are not final until full payment for the product and service concerned is made. A booking fee may be payable on some bookings. Some airport taxes are not pre-payable or pre-paid; you must pay these when required. Some airlines or travel service providers have introduced fuel surcharges which may not be reflected in the prices shown and will be an additional charge. For travel services such as air tickets, hotels, cars, packages or cruises-availability, prices, fare conditions and class of travel can change at any time without notice. Routing restrictions and other special conditions may apply.</p>
               <p><b>9. Decline, Non-utilisation or cancellation of Booking & Purchase</b><br/>
               Please note that the terms and conditions for cancellation of bookings and purchases will vary for each product and service. Please therefore refer to the terms and conditions for a specific product or service or alternatively, check with our Sales and Customer Care Team during office hours. If there are no terms and conditions for a specific product or service, you may cancel your booking or purchase by calling our Sales and Customer Care Team or alternatively visiting any one of our fulfilment centres (if applicable) during office hours. For certain services, you may also cancel your booking or purchase online at the site. Cancellation, at your request, may not be allowed or may require a minimum processing time of varying periods, subject to the specific terms and conditions applicable to the type of product or service purchased. Cancellation may be subject to cancellation fees which you agree to bear in full. Please note that cancellations of ticketed air tickets are not permitted.</p>
               <p>The Operator reserves the right to decline any booking or purchase for any reason and shall not be held liable for any resulting claims for loss, damages or compensation. In such an event, the Operator shall refund to you all unutilized monies collected from you for that purchase.</p>
               <p>For bookings and purchases which have been pre-paid, refunds will be made (subject to the refund policy). It may take an estimated one to two billing cycles (3 to 6 months) before the credit appears in your account for air ticket refund processing. For pre-paid hotel refund processing, it may take approximately ten (10) to twelve (12) business days for the credit to appear in your account.</p>
               <p>The preceding refund timeline is a guide for your reference only and shall not be binding upon the Operator.</p>
               <p>You agree to bear the full cost of any booking, cancellation and administration fees for any products or services booked but not utilized for any reason. In some cases, the Operator may charge cancellation and/or amendment fees in addition to those imposed by travel service providers.</p>
               <p><b>10. Changes To Booking & Purchase</b><br/>
               Please note that the terms and conditions for changes to bookings and purchases will vary for each product and service. Please therefore refer to the terms and conditions for a specific product or service. If there are no terms and conditions for a specific product or service, you may NOT change your booking or purchase online at the site. You may otherwise change your booking or purchase online by calling our customer service center during office hours. Changes, at your request, must be received by the Operator at least (7) days before travel or use to be effective and may be subject to administrative fees which you agree to bear in full.</p>
               <p><b>11. Disclaimer</b><br/>
               TO THE FULLEST EXTENT PERMITTED BY LAW AND SUBJECT TO THE STATUTORY WARRANTIES BELOW, THE OPERATOR AND Skiddoo MAKE NO WARRANTY OR REPRESENTATION OF ANY KIND REGARDING THIS SITE, PRODUCTS OR SERVICES ON THIS SITE AND/OR ANY MATERIALS PROVIDED ON THIS SITE, ALL OF WHICH ARE PROVIDED ON AN "AS IS" BASIS ONLY. THE OPERATOR AND Skiddoo DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENCY OR RELIABILITY OF ANY OF THE CONTENT OR DATA FOUND ON THIS SITE AND, SUBJECT TO THE STATUTORY WARRANTIES BELOW, EXPRESSLY DISCLAIM ALL WARRANTIES, TERMS AND CONDITIONS, INCLUDING ALL IMPLIED WARRANTIES, TERMS AND CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A GENERAL OR PARTICULAR PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS, AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE TO THE FULLEST EXTENT PERMITTED BY THE LAWS OF THE TERRITORIES. THE OPERATOR AND Skiddoo DO NOT WARRANT THAT THIS SITE, ITS SERVERS OR ANY E-MAIL SENT FROM THE OPERATOR OR Skiddoo ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. SOME TERRITORIES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO TO THAT EXTENT THE FOREGOING DISCLAIMER MAY NOT APPLY TO YOU. IN PARTICULAR, IN TERRITORIES WHERE IT IS PROHIBITED, THIS DISCLAIMER SHALL NOT IN ANY WAY EXCLUDE OR LIMIT LIABILITY FOR PERSONAL INJURY OR DEATH ARISING FROM NEGLIGENCE, WILFUL MISCONDUCT OR FRAUD. THIS WARRANTY GIVES YOU SPECIFIC RIGHTS WHICH VARY FROM TERRITORY TO TERRITORY. Statutory Warranties. Certain legislation (including the Trade Practices Act 1974 in Australia) may imply warranties or conditions or impose obligations which cannot be excluded, restricted or modified except to a limited extent. These Terms and Conditions must be read subject to any such statutory provisions. If such statutory provisions apply, to the extent to which the Operator and Skiddoo are entitled to do so, the liability of each will be limited at their option to:<br/>
               a. in the case of supply of goods, the replacement of the goods or supply of equivalent goods, the payment of the cost of replacing the goods or acquiring equivalent goods, the payment of the cost of having the goods repaired or the repair of the goods; and <br/>
               b. in the case of services, the supply of the services again, or the payment of the cost of having the services performed again.</p>
               <p><b>12. Limitation Of Liability</b><br/>
               TO THE FULL EXTENT PERMITTED BY LAW BUT SUBJECT ALWAYS TO CLAUSE 10,<br/>
               <b>a.</b> THE OPERATOR AND Skiddoo ASSUME NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF, OR BROWSING IN THIS SITE OR YOUR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO OR AUDIO FROM THE SITE;<br/>
               <b>b.</b> IN NO EVENT SHALL THE OPERATOR OR Skiddoo BE LIABLE FOR ANY INJURY, LOSS, CLAIM, DAMAGE, OR ANY SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOST PROFITS OR LOST SAVINGS, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), EQUITY, STRICT LIABILITY, STATUTE OR OTHERWISE, WHICH ARISES OUT OF OR IS IN ANY WAY CONNECTED WITH (I) ANY USE OF THIS SITE OR CONTENT FOUND HEREIN, (II) ANY FAILURE OR DELAY (INCLUDING, BUT NOT LIMITED TO THE USE OF OR INABILITY TO USE ANY COMPONENT OF THIS SITE FOR RESERVATIONS OR TICKETING), OR (III) THE PERFORMANCE OR NON PERFORMANCE BY THE OPERATOR EVEN IF THE OPERATOR HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES TO SUCH PARTIES OR ANY OTHER PARTY.<br/>
               If, notwithstanding the foregoing, the Operator or Skiddoo should be found liable for any loss or damage which arises out of or is in any way connected with any of the above described functions or uses of this site or its content, to the full extent permitted by law the combined aggregate liability of the Operator and Skiddoo shall in no event exceed the value of the goods and services purchased by you which are the subject of the dispute or S$1000.00, whichever is lower. Further, the Operator's and Skiddoo ' s liability to you in contract, tort, negligence, strict liability, under statute or otherwise will be reduced to the extent, if any, to which you contributed to the loss or damage. In its sole discretion, in addition to any other rights or remedies available to the Operator or Skiddoo without any liability whatsoever, the Operator or Skiddoo may, at any time and without notice, terminate or restrict your access to and/or of any component of this site. Some territories do not allow limitation of liability, so to that extent the foregoing limitation may not apply to you.</p>
               <p><b>13. Indemnification</b><br/>
               You shall defend and indemnify the Operator and Skiddoo and its officers, directors, employees and agents from and against any claim, cause of action or demand, including without limitation reasonable legal and accounting fees, brought by or on your behalf in excess of the liability described herein or by third parties as a result of your use of this site.</p>
               <p><b>14. Links</b><br/>
               This site may contain links to other web sites which are not maintained by the Operator and which are provided solely as a convenience to you and not as an endorsement of the contents of such other web sites (whether express or implied) by the Operator. The Operator has not reviewed all of the linked web sites and shall not be responsible for the content of any other web sites and make no representation or warranty regarding any other web sites or the contents or materials on such web sites. If you decide to link to other web sites, you do so at your own risk and subject to the terms and conditions (if any) of the linked web site and in particular, its own set of privacy policies that you are advised to check out.<br/>
               Similarly, other web sites may also link to this site. Any third parties that wish to establish links with this site should obtain the prior written consent of the Operator to do so and the Operator reserves the right to deny permission for any such links to the site. In particular, no third parties shall deep link to any page other than the Home page of this site without obtaining the prior written consent of the Operator to do so. If however the Operator gives its permission for any such links, the Operator is not under any obligation to establish reciprocal links with the third party.</p>
               <p><b>15. Relationship</b><br/>
               The relationship between the Operator and you will be that of independent contractors, and neither of us nor any of our respective officers, agents or employees will be held or construed to be partners, joint ventures, fiduciaries, employees or agents of the other.</p>
               <p><b>16. Governing Law & Jurisdiction</b><br/>
               The Terms and Conditions, its performance and all disputes arising out of or in connection with the Terms and Conditions shall be governed by Australian law.</p>
               <p><b>17. Legal Fees & Expenses</b><br/>
               If the Operator or Skiddoo or any its affiliates take any action to enforce the Terms and Conditions, such parties will be entitled to recover from you, and you agree to pay, all legal fees and expenses and any cost of litigation, in addition to any other relief, at law or in equity, to which such parties may be entitled.</p>
               <p><b>18. Injunctive Relief</b><br/>
               You acknowledge that a violation or attempted violation of any of the Terms and Conditions will cause such damage to the Operator or Skiddoo as will be irreparable, the exact amount of which would be difficult to ascertain and for which there will be no adequate remedy at law. Accordingly, you agree that the Operator or Skiddoo shall be entitled as a matter of right to an injunction (including a temporary injunction) issued by any court of competent jurisdiction, restraining such violation or attempted violation of these terms and conditions by you, or your affiliates, partners, or agents, as well as recover from you any and all costs and expenses sustained or incurred by the Operator or Skiddoo in obtaining such an injunction, including, without limitation, all legal fees and expenses.</p>
               <p><b>19. Termination</b><br/>
               The Operator may terminate the Terms and Conditions and/or the provision of any of the products and services at any time for any reason, including any improper use of this site or your failure to comply with the Terms and Conditions. Such termination shall not effect any right to relief to which the Operator or Skiddoo may be entitled, at law or in equity. Upon termination of the Terms and Conditions, all rights granted to you will terminate and revert to the Operator or Skiddoo and its third party providers or distributors, as applicable.</p>
               <p><b>20. Assignment</b><br/>
               You may not assign, convey, subcontract or delegate your rights, duties or obligations hereunder.</p>
               <p><b>21. Modification</b><br/>
               The Operator may at any time modify any part of the site, including the products and services and the Terms and Conditions at its sole discretion without prior notice and your continued use of this site will be conditioned upon the terms and conditions in force at the time of your use. The Operator also reserves the right to deny any person access and use of the site at any time.</p>
               <p><b>22. Additional Terms</b><br/>
               Additional terms and conditions may apply to reservations, purchases of products and services and other uses of portions of this site, and you agree to abide by such other terms and conditions, including additional terms and conditions that apply in relation to specific products and services.</p>
               <p><b>23. Severability</b><br/>
               The Terms and Conditions shall be severable. In the event that any provision is determined to be unenforceable, illegal or invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and such determination shall not affect the enforceability, legality and validity of any other remaining provisions.</p>
               <p><b>24. Headings</b><br/>
               The headings used in the Terms and Conditions are included for convenience only and will not limit or otherwise affect the terms and conditions herein.</p>
               <p><b>25. Entire Agreement</b><br/>
               The Terms and Conditions, together with any terms and conditions incorporated herein or referred to herein constitute the entire agreement between us relating to the subject matter hereof, and supersedes any prior understandings or agreements (whether oral or written) regarding the subject matter, and may not be amended or modified except in writing or by making such amendments or modifications available on this site.</p>
               <p><b>26. Communication With Users</b><br/>
               The Operator reserves the right to communicate with you regarding this site and your use of this site or any product or service purchased by you on this site.</p>
               <p>This product is provided by Skiddoo Pty Ltd ABN 22 141 736 094. Copyright © Skiddoo Pty Ltd</p>
               </font>
            </div>

            </Container>
          </div>

        </div>

      </div>
    );
  }
}

export default TermsConditionsComponent;
