import "./index.css";

import React from "react";

import { Provider } from "react-redux";
import store from "./stores/redux-store";

import { HashRouter } from "react-router-dom";
import Routes from "./Routes";
import { Link } from 'react-router-dom';
import {Footer} from "mdbreact";


class App extends React.Component {

  render() {
    return (

      <Provider store={store}>
        <HashRouter>
          <div className="flyout">

            <main style={{ marginTop: "4rem" }}>
              <Routes />
            </main>

            <Footer className="fixed-footer">
              <p className="mb-0 py-3 text-center">
                <font size="2">
                {/*<li><a href="https://www.skiddoo.com.au/about-us">About Skiddoo</a></li>*/}
                {/*<li><a href="#">Skiddoo Insider</a></li>*/}
                <li><Link to="/contact-us"><a href="#">Contact Us</a></Link></li>
                {/*<li><a href="https://www.skiddoo.com.au/help">Help</a></li>*/}
                {/*<li><a href="#">Privacy Policy</a></li>*/}
                {/*<li><a href="https://www.skiddoo.com.au/cookies-policy">Cookies Policy</a></li>*/}
                <li><Link to="/booking-terms-and-conditions"><a href="#">Booking Terms and Conditions</a></Link></li>
                <li><Link to="/terms-and-conditions"><a href="#">Terms and Conditions</a></Link></li>
                {/*<li><a href="https://www.skiddoo.com.au/partners-au">Partner With Us</a></li>*/}
                </font>
              </p>
              <p className="mb-0 py-3 text-center">
                <font size="2">© 2021 Skiddoo Pty Ltd ABN 22 141 736 094 ATAS A10808</font>
              </p>
            </Footer>

          </div>

        </HashRouter>
      </Provider>

    );
  }
}

export default App;
