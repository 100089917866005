import React from "react";

import "./homepage.css";
import HeaderContainer from '../header/header-container';
import { Container } from "mdbreact";
import { Link } from 'react-router-dom';

class HomePage extends React.Component {
  render() {
    let contactLink = '/contact-us';

    return (
      <div className="home-component">
        <HeaderContainer {...this.props} />
        <div>
          <div>
            <Container  className="mt-4 search-filter">

            <div className="search-label">
               <font size="4"><p>Due to the impact of Covid-19 and the closure of International borders, Skiddoo are currently not accepting new bookings.</p>
               <p>Our teams are working around the clock servicing date change requests, cancellations and refunds and we thank you for your patience and understanding.  We do recognise that many airlines refunds can take up to 6+ months and thank you for your understanding whilst we follow up with the appropriate carrier.  Please continue to contact us via this page for all your queries.</p>
               <p>We would like to thank all our loyal customers for your support and understanding during these difficult times.  We cannot wait until we can once again take to the skies and explore the world!</p></font>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="search-label">
            <Link to={contactLink} >
            <button className="btn btn-danger btn-md">Contact Us</button>
            </Link>
            </div>
            </Container>
          </div>

        </div>

      </div>
    );
  }
}

export default HomePage;
