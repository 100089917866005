import React from "react";
import { Route, Switch } from "react-router-dom";

import HeaderContainer from './components/header/header-container';
import HomePage from "./components/home/homepage";
import ContactUsPage from "./components/contact/contact-container";
import TermsConditionsPage from "./components/terms/terms-cond-container";
import BkgTermsConditionsPage from "./components/terms/bkg-terms-cond-container";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/contact-us" component={ContactUsPage} />
        <Route exact path="/terms-and-conditions" component={TermsConditionsPage} />
        <Route exact path="/booking-terms-and-conditions" component={BkgTermsConditionsPage} />
        <Route path="/*" component={HeaderContainer} />

        <Route render={function() {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;
